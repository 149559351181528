import React from 'react';
import styled from 'styled-components';

import content from 'data/404.json';
import {
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    DESKTOP_GRID_SIZE,
    TABLET_GRID_SIZE
} from 'consts';
import analytics from 'data/analytics.json';
import { useMediaQuery } from 'hooks';

import { PrimaryButton, SEO } from 'components/ui';
import { MediaQueries, Sizes, Colors } from 'environment';

export default function NotFoundPage() {
    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const isTablet = useMediaQuery(MediaQueries.tablet);

    return (
        <Container>
            <SEO title="404 - Page not found" />
            <Content>
                <Image src="/images/not-found.svg" alt="Not found icon" />
                <Title>{content.title}</Title>
                <Description>{content.description}</Description>
                <PrimaryButton
                    name={analytics.nonExistingPage}
                    to="/"
                    width={isDesktop ? 380 : isTablet ? 300 : '100%'}
                >
                    {content.cta}
                </PrimaryButton>
            </Content>
        </Container>
    );
}

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 12rem;
    margin-bottom: 12rem;

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        margin-top: 5.5rem;
        margin-bottom: 6rem;

        & > a {
            width: 100%;
        }
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
        padding: 0 2rem;
    }
`;

const Image = styled.img`
    @media ${MediaQueries.phone} {
        width: calc(100vw - 4rem);
    }
`;

const Title = styled.h1`
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    color: ${Colors.violet};

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 5rem;
        margin-top: 2rem;
    }
`;

const Description = styled.p`
    white-space: break-spaces;
    margin-bottom: 4rem;
    max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
    text-align: center;
    color: ${Colors.violet};

    @media ${MediaQueries.desktop} {
        white-space: pre;
    }

    @media ${MediaQueries.phone} {
        white-space: unset;
        margin-bottom: 2rem;
    }
`;
